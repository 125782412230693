import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import styled from "styled-components";
import { Feed, loadFeedInformation } from "../actions/feed";

export const About = () => {
  const [feed, setFeed] = useState<Feed | false>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    loadFeedInformation()
      .then(data => {
        setLoading(false)
        setFeed(data)
      })
  }, [])
  
  return (
    <Container>
      {loading && 
      <div></div>}
      {!loading && feed && 
        <AboutContainer>
          <AboutImg src={feed.image} alt=""/>
          <h2>{feed.title}</h2>
          <p>{feed.url}</p>
          <AboutFlex>
            {feed.followers < 2 
              ? 
              (<p><span>{feed.followers}</span> follower</p>) 
              : 
              (<p><span>{feed.followers}</span> followers</p>)
            }
            {feed.posts < 2 
              ?
              (<p><span>{feed.posts}</span> post / day</p> )
              :
              (<p><span>{feed.posts}</span> posts / day</p> )
            }
          </AboutFlex>
          <FeedDesc>{feed.desc}</FeedDesc>
        </AboutContainer>
      }
      <What>
        <WhatI>i</WhatI>
        <h3>What's this?</h3>
        <p>This is an RSS feed. You can add them to a feed reader.</p>
        <a href="https://feeder.co/help/rss/what-is-rss-and-how-can-you-use-it/" target="_blank">Learn more</a>
      </What>
    </Container>
  )
}

const Container = styled.div`
  background-color: #eeeff0;
  padding: 6rem 1rem 3rem 1rem;
  width: 21.75rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 4rem 1rem 0 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
  }
`
const AboutContainer = styled.div`
  padding: 0 1rem;
  margin-bottom: 3rem;
  width: 19rem;

  @media (max-width: 768px) {
    padding: 0 1rem
    
  }
  @media (max-width: 650px) {
    width: 100vw;
    padding: 0 5rem;
    margin-bottom: 1rem;
  }
`
const AboutImg = styled.img`
  width: 4.5rem;
  border-radius: 50%;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    width: 5rem;
  }
  @media (max-width: 650px) {
    width: 4rem;
  }
`
const AboutFlex = styled.div`
  margin: 1.2rem 0;
  padding-right: 1.2rem;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 1.25;

  @media (max-width: 650px) {
    width: 16rem;
  }
`
const FeedDesc = styled.p`
  line-height: 1.35;
    
`
const What = styled.div`
  width: 20.25rem;
  height: 14.25rem;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  margin: 2rem 0;
  padding: 1.375rem 0.75rem 1.5rem;
  text-align: center;
  color: #707070;

  @media (max-width: 650px) {
    margin-bottom: 1rem;
  }
`
const WhatI = styled.p`
  border: 2px solid #d3d3d3;
  border-radius: 50%;
  margin: auto;
  width: 3rem;
  height: 3rem;
  padding: 0.3rem;
  font-weight: 700;
  font-size: 1.625rem;
  color: #707070;
`