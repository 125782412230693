import { timeout } from "../lib/timeout";

export interface Reader {
  id: string;
  name: string;
}

  
// Load all readers from the database. This will also include any custom readers
// Note that it is async because this will take some time
export async function loadReaders() {
  await timeout(800);

  const customReaders = getCustomReaders();

  const defaultReaders = [
    {
      id: "feeder",
      name: "Feeder"
    },
    {
      id: "feedly",
      name: "Feedly"
    },
    {
      id: "feedbin",
      name: "Feedbin"
    },
    {
      id: "inoreader",
      name: "Inoreader"
    },
  ];

  return defaultReaders.concat(customReaders);
}

// Update the selected reader. The argument is the ID of the selected reader
// Note that it is async because the future API call will take some time
export async function setSelectedReader(readerId: string){
  await timeout(800);
  localStorage.setItem("selectedReader", readerId);
}

// Gets the ID of the currently selected reader
export function getSelectedReader() {
  return localStorage.getItem("selectedReader") || "feeder";
}

export function getCustomReaders() {
  return JSON.parse(localStorage.getItem("customReaders") || "[]");
}

// Adds a new reader option to the list of readers
// Note that it is async because this will take some time
export async function addCustomReader(name: string) {
  await timeout(800);

  const readers = getCustomReaders();
  readers.push({
    id: name,
    name: name
  });
  localStorage.setItem("customReaders", JSON.stringify(readers));
}
