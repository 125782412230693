import { h } from 'preact';
import { useState } from 'preact/hooks';
import styled from "styled-components";
import { LoadingIndicatorDots } from './LoadingIndicator';

export const Updates = () => {
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [thank, setThank] = useState(false);

    const handleChange = (e: Event) => {
        if (e.target instanceof HTMLInputElement) {
            setEmail(e.target.value)
        }
    };

    const handleSubmit = (e: SubmitEvent) => {
        if (e.target instanceof HTMLFormElement) {
            e.preventDefault();
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                setThank(true)
            }, 800)
            e.target.reset();
        }
      };

    return (
        <Container>
            <h3>Get updates to your inbox</h3>
            {!thank && !loading &&
                <Update onSubmit={handleSubmit}>
                    <UpdateInput 
                        type="email" 
                        name="email" 
                        id="email" 
                        placeholder='Enter your email...'
                        value={email}
                        onInput={handleChange}
                    />
                    <UpdateBtn type='submit'>Subscribe</UpdateBtn>
                </Update>   
            }
            { !thank && loading && 
                <LoadingIndicatorDots/>
            }
            { !loading && thank && 
                <p>Thank you for subscribing!</p>
            }
        </Container>
    )
}

const Container = styled.div`
	margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top: 1px solid #eeeff0;
	border-bottom: 1px solid #eeeff0;
	padding: 1rem 0 2rem 0;
    text-align: center;
`
const Update = styled.form`
	display: flex;
	justify-content: center;
	align-items: center;
    margin-top: 0.875rem;
`
const UpdateInput = styled.input`
	border-radius: 8px 0 0 8px;
	border: 1px solid transparent;
    background-color: #eeeff0;
    padding: 0.375rem 1.3rem;
	font-size: 1rem;
    line-height: 1.25;
    color: #000;
    font-family: 'Fira Sans', sans-serif;
    width: 19rem;


    ::placeholder {
        color: black;
        font-size: 1rem;
    }
    :focus {
        border: 1px solid transparent;
        outline: none;
        background-color: #ebebeb;
    }
`
const UpdateBtn = styled.button`
	background-color: #5b4ecf;
    margin-left: -1rem;
	color: #fff;
	border: 1px solid transparent;
	border-radius: 8px;
	padding: 0.375rem 1.3rem;
	font-size: 1rem;
    font-weight: bold;
    line-height: 1.25;
    cursor: pointer;

    :hover {
        background-color: #7160e6;
    }
`