import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';
import { subscribeToFeedUsingReader } from '../actions/feed';
import { addCustomReader, loadReaders, Reader } from '../actions/readers';

export const Header = () => {
	const [readers, setReaders] = useState<Reader[]>([]);
	const [readerPick, setReaderPick] = useState<string>("");
	const [newReader, setNewReader] = useState<boolean>(false);
	const [subscribing, setSubscribing] = useState<boolean>(false);
	
	useEffect(() => {
		loadReaders()
			.then(data => {
				setReaders(data)
				setReaderPick('feeder')
			})
	}, [])

	const onChange = (e: Event) => {
		if (e.target instanceof HTMLSelectElement) {
			setReaderPick(e.target.value)

			if(e.target.value === "add") {
				setNewReader(true)
			}
		}
	};

	const handleChange = (e: Event) => {
		if (e.target instanceof HTMLInputElement) {
		e.preventDefault();
		setReaderPick(e.target.value)
		}
	}

	const handleSubmit = (e: SubmitEvent) => {
        e.preventDefault();
		if(newReader) {
			addCustomReader(readerPick);
		}
		subscribeToFeedUsingReader(readerPick);
		setSubscribing(true);
	};

	return (
		<Container>
			<h2>LOGO</h2>
			{ !subscribing &&
				<Subscribe onSubmit={handleSubmit}>
					<p>Subscribe to this feed, using:</p>
					{ !newReader &&
						<SubSelect value={readerPick} onChange={onChange}>
							{
								readers.map(reader => (
									<option key={reader.id} value={reader.id}>{reader.name}</option>
								))
							}
							<option value={"add"}>{"Add new..."}</option>
						</SubSelect>
					}
					{ newReader &&
						<SubInput type="text" placeholder='Name of reader' onInput={handleChange}/>
					}
					<SubBtn type='submit'>Subscribe now</SubBtn>
				</Subscribe>
			}
			{ subscribing &&
				<Subscribe>
					<p>Thank you for subscribing to this feed with {readerPick}!</p>
				</Subscribe>
			}
		</Container>
	)
};
const Container = styled.div`
	position: fixed;
	width: 100vw;
	height: 3rem;
	margin: 0;
	padding: 0.25rem 0.75rem;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	background-color: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
`
const Subscribe = styled.form`
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: center;
	align-items: center;


	p {
		margin: 0.19rem 0.75rem 0.19rem 0;
		line-height: 1.25;
	}
`
const SubSelect = styled.select`
	border-radius: 8px;
	border: 1px solid #d3d3d3;
	padding: 0.2rem 0.44rem 0.2rem 0.56rem;
	margin: 0 1rem;
	font-size: 1rem;
	font-weight: bold;
	font-family: 'Fira Sans', sans-serif;
	width: 9rem;

	:focus {
		outline: none;
	}
	:active {
		background-color: 777878;
	}
`
const SubInput = styled.input`
	border-radius: 8px;
	border: 1px solid #d3d3d3;
	padding: 0.25rem 0.44rem 0.25rem 0.56rem;
	margin: 0 1rem;
	font-size: 1rem;
	width: 9rem;
	font-family: 'Fira Sans', sans-serif;
	

	:focus {
		outline: none;
	}
`
const SubBtn = styled.button`
	background-color: #5b4ecf;
	color: #fff;
	border: 1px solid transparent;
	border-radius: 8px;
	padding: 0.25rem 1rem;
	font-size: 1rem;
	font-weight: bold;
	cursor: pointer;

	:hover {
		background-color: #7160e6;
	}
`


