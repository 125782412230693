import { timeout } from "../lib/timeout"

export interface Feed {
  id: number;
  image: string;
  title: string;
  url: string;
  followers: number;
  posts: number;
  desc: string;
}

// Will trigger API calls to subscribe the user to the current feed
export async function subscribeToFeedUsingReader(readerId: string) {
  console.log("Loading...");
  await timeout(800);
  console.log("Done!");
  console.log("Subscribed to is " + readerId);
  return true;
}

// Loads information about the current feed from the API
// Note that it is async because it will take some time
export async function loadFeedInformation() {
  await timeout(800);
  
  return {
    id: 1,
    image: "https://nitter.net/pic/profile_images%2F877903823133704194%2FMqp1PXU8.jpg",
    title: "The Verge", 
    url: "www.theverge.com",
    followers: 2400,
    posts: 60,
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam harum ab ut corrupti, ullam quam voluptas esse molestiae."
  }
}
