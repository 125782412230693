import { h } from 'preact';
import styled from 'styled-components';
import { Header } from './Header';
import { FeedArea } from './FeedArea';
import { About } from './About';

export const App = () => (
	<div id="app">
		<Header/>
		<Container>
			<About/>
			<FeedArea/>
		</Container>
	</div>
);

const Container = styled.div`
	margin: auto;
	display: flex;
	align-items: start;

	@media (max-width: 768px) {
    display: block;
  }
`
