import { h } from "preact";
import styled from "styled-components";
import { Post } from "../actions/posts";

export const PostItem = ({post}: {post: Post}) => {

  return (
    <PostListItem key={post.id}>
      <FlexDiv>
        <div>
          <SourceLogo src={post.sourcelogo} alt=""/>
          <small>{post.source}</small>
        </div>
        <small>{post.date}</small>
      </FlexDiv>
      <FlexDiv2>
        <PostDiv>
          <h2>{post.title}</h2>
          {post.body.length < 130 
            ? 
            (<p>{post.body}</p>)
            :
            (<p>{post.body.slice(0, 130)}...</p>)
          }
        </PostDiv>
        <PostImage src={post.image} alt=""/>
      </FlexDiv2>
    </PostListItem> 
  )
}

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }
`
const FlexDiv2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 650px) {
    display: block;
  }
`
const PostListItem = styled.li`
  padding: 1rem 0 0.5rem 0;
`
const SourceLogo = styled.img`
  width: 1.125rem;
  height: 1rem;
  margin-right: 0.56rem;
`
const PostDiv = styled.div`
  margin: 0 1rem 0.44rem 0;

  p {
    margin-top: 0.44rem;
  }
`
const PostImage = styled.img`
  width: 8.25rem;
  border-radius: 8px;
  margin-top: 0.75rem;

  @media (max-width: 1024px) {
    width: 8rem;
  }
  @media (max-width: 650px) {
    display: none;
  }
`