import { h } from "preact";

export const LoadingIndicatorRipple = () => {
    return (
        <div className="lds-ripple"><div></div><div></div></div>
    )
}

export const LoadingIndicatorDots = () => {
    return (
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    )
}