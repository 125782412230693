import { h } from "preact";
import styled from "styled-components";
import { Updates } from "./Updates";
import { loadPosts, Post } from '../actions/posts';
import { useEffect, useState } from "preact/hooks";
import { LoadingIndicatorRipple } from "./LoadingIndicator";
import { PostItem } from "./PostItem";

export const FeedArea = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    loadPosts()
      .then(items => {
        setPosts(items)
        setLoading(false)
      })
  }, [])

  return (
    <Container>
      <PostList>
        {loading && <LoadingIndicatorRipple/>}
        {!loading && posts.map(post => (
          post.id < 3
          ?
          (<PostItem key={post.id} post={post}/>) 
          : 
          (<div>
            <Updates/>
            <PostItem key={post.id} post={post}/>
          </div>)
        ))}
      </PostList>
    </Container>
  )
}

const Container = styled.div`
  padding: 7.4rem 2rem 3rem 2rem;
  margin: auto;
  border-radius: 3px;
  width: 65vw;
  height: 100vh;

  @media (max-width: 1200px){
    padding: 7.4rem 1rem 3rem 1rem;    
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 65vh;
    padding: 1rem 3rem;
  }
`
const PostList = styled.ul`
  list-style: none;
  padding: 1rem 2rem 0.5rem 2rem;
  margin: 0;

  @media (max-width: 1024px) {
    padding: 1rem 0 0.5rem 0;
  }
  
  @media (max-width: 768px) {
    padding: 1rem 1rem 0.5rem 1rem;
  }
`