import { timeout } from "../lib/timeout";

export interface Post {
  id: number;
  sourcelogo: string;
  source: string;
  date: string;
  title: string;
  body: string;
  image: string;
}
// Fetch all posts for this page.
// Note that it is async because this will take some time
export async function loadPosts() {
  await timeout(800);

  return [
    {
      id: 1,
      sourcelogo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/The_Verge_Logo_2016.svg/1200px-The_Verge_Logo_2016.svg.png",
      source: "The Verge",
      date: "8m ago",
      title: "Canon's EOS R5C is a 2-in-1 stills and cinema camera",
      body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt consectetur adipisicing elit. Totam harum ab ut corrupti, ullam quam voluptas esse molestiae.",
      image: "https://images.unsplash.com/photo-1600176842064-635fe81d2441?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&q=80"
    },
    {
      id: 2,
      sourcelogo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/The_Verge_Logo_2016.svg/1200px-The_Verge_Logo_2016.svg.png",
      source: "The Verge",
      date: "1h ago",
      title: "Post 2",
      body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt aliquam asperiores nesciunt ipsum culpa consectetur adipisicing elit.",
      image: "https://images.unsplash.com/photo-1560169897-fc0cdbdfa4d5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&q=80"
    },
    {
      id: 3,
      sourcelogo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/The_Verge_Logo_2016.svg/1200px-The_Verge_Logo_2016.svg.png",
      source: "The Verge",
      date: "2h ago",
      title: "Post 3",
      body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt aliquam asperiores nesciunt ipsum culpa.",
      image: "https://images.unsplash.com/photo-1600176842064-635fe81d2441?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&q=80"
    }
  ]
}